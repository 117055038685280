import { useEffect } from 'react';
import { Toast } from 'react-bootstrap';

const ToastNotification = ({ type, show_toast, set_show_toast, message, time = 5000 }) => {
    useEffect(() => {
        if (show_toast) {
            const interval = setTimeout(() => {
                set_show_toast(false);
            }, time);

            return () => clearTimeout(interval);
        }
    }, [show_toast, set_show_toast, time]);

    return (
        <div className="toast-container position-fixed top-0 end-0 p-3">
            <Toast id='toast-container-main' role="alert" onClose={() => set_show_toast(false)} show={show_toast} aria-live="assertive" aria-atomic="true" className={`bg-${type} text-white text-bold`}>
            <div className='d-flex'>
                <Toast.Body>{message}</Toast.Body>
                <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </Toast> 
        </div>
    );
};

export default ToastNotification;
