import "./bootstrap.min.css";
import "./App.css";
import { useState, useEffect } from "react";
import ToastNotification from './components/Toast';


function App() {
  const [classadd, setclassadd] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [submitCheck, setSubmitCheck]= useState(false);
  const [toastProps, setToastProps] = useState(null);
  const [showToast, setShowToast] = useState(false);

  const addclassname = () => {
    setclassadd(classadd ? false : true);
  };

  const handleSubmitCheck = () => {
    setSubmitCheck((prev) => !prev);  
  };

  
   useEffect(() => {

      if (toastProps) {
         setShowToast(true);
      }

   }, [toastProps]);

  function validateForm() {

    let valid = true;

    if (!name.trim()) {
       valid = false;
       setToastProps({ message: 'Name required', type: 'danger' });
    }
    else if (!phoneNumber) {
       valid = false;
       setToastProps({ message: 'Phone Number is required', type: 'danger' });
    }

    return valid;

  }

  async function submitForm(e) {

    e.preventDefault();

    if (!validateForm()) {
       return;
    }

    let params = {
       name,
       email,
       mobile: phoneNumber,
       description
    }

    fetch('https://api.leadcenter.me/api/v1/contact/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setToastProps({ message: 'Success', type: 'success' });
          setSubmitCheck(false)
          setName('');
          setEmail('');
          setPhoneNumber('');
          setDescription('');
        } else {
          console.error('Error:', data.message);
          setToastProps({message: JSON.stringify(data.error), type: 'danger'});
        }
      })
      .catch(error => console.error('Error:', error));
  }

  return (
    <>
    <div className="App">
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl position-relative d-flex align-items-center">
          <a
            href="index.html"
            className="logo d-flex align-items-center me-auto"
          >
            <img src="logo2.png" alt="" />
          </a>
          <nav
            id="navmenu"
            className={classadd ? " navmenu menu-active" : "navmenu"}
          >
            <button className="mobile-nav-toggle-remove" onClick={addclassname}>
              {" "}
              <img src="cross.png" className="img-fluid animated" alt="" />
            </button>
            <ul>
              <li>
                <a href="#hero" className="active">
                  Home
                  <br />
                </a>
              </li>
              <li>
                <a href="#story">Our Story</a>
              </li>
              <li>
                <a href="#mission">Our Mission</a>
              </li>
              <li>
                <a href="#values">Our Values</a>
              </li>
              <li>
                <a href="#services">Our Services</a>
              </li>
              <li>
                <a href="#choose">Why Choose Us?</a>
              </li>
              <li>
                <a href="#terms&conditions">Terms & Conditions</a>
              </li>
            </ul>
          </nav>
          <a class="btn-getstarted flex-md-shrink-0" href="#contact">
          Contact Us
          </a>
          <button className="mobile-nav-toggle" onClick={addclassname}>
            {" "}
            <img src="menu.png" className="img-fluid animated" alt="" />
          </button>
        </div>
      </header>

      {/* Hero Section */}
      <section id="hero" className="hero section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-7 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Welcome to Lead Center!</h1>
              <p>
                At Lead Center, we specialize in connecting financial
                institutions, share trading companies, and property sellers with
                potential customers, driving growth and success in these
                industries. As experts in lead generation, we act as a vital
                link between these entities and individuals seeking their
                services.
              </p>
              <div className="d-flex flex-column flex-md-row">
                <a href="#story" className="btn-get-started">
                  Get Started <i className="bi bi-arrow-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 hero-img">
              <img
                src="main-banner.png"
                className="img-fluid animated"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* /Hero Section */}

      {/* Our Story Section */}
      <section id="story" className="values section">
        {/* Section Title */}
        <div className="container" data-aos="fade-up">
          <div className=" section-title no-pb" data-aos="fade-up">
            <h2>About Us</h2>
            <p>Our Story</p>
          </div>
          <p className="text-center m-w">
            Founded in 2021, Lead Center was created with the goal of
            transforming how companies attract new customers. Our team brings
            years of experience across the financial sector, share trading, real
            estate, political surveys, and more, understanding the unique
            challenges and opportunities of lead generation.
          </p>
        </div>

        {/* End Section Title */}
      </section>
      {/* /Our Story Section */}
      {/* Our Mission Section */}
      <section id="mission" className="values section">
        {/* Section Title */}
        <div className="container" data-aos="fade-up">
          <div className=" section-title no-pb" data-aos="fade-up">
            <h2>Mission</h2>
            <p>Our Mission</p>
          </div>
          <p className="text-center m-w">
            Our mission is to deliver high-quality leads to financial
            institutions, share trading firms, and real estate professionals,
            allowing them to concentrate on their core competencies – serving
            their clients. We aim to build enduring partnerships with our
            clients, offering customized solutions that address their specific
            needs.
          </p>
        </div>

        {/* End Section Title */}
      </section>
      {/* /Our Mission Section */}

      <>
        {/* Values Section */}
        <section id="values" className="values section">
          {/* Section Title */}
          <div className="container section-title" data-aos="fade-up">
            <h2>Our Values</h2>
            <p>
              What we value most
              <br />
            </p>
          </div>
          {/* End Section Title */}
          <div className="container">
            <div className="row gy-3">
              <div className="col-lg-3" data-aos="fade-up" data-aos-delay={100}>
                <div className="card">
                  <img src="001-technique.png" className="img-fluid" alt="" />
                  <h3>Expertise</h3>
                  <p>
                    We excel in lead generation across financial services, real
                    estate, share trading, and political surveys, staying
                    current with industry trends and best practices.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div className="col-lg-3" data-aos="fade-up" data-aos-delay={200}>
                <div className="card">
                  <img
                    src="003-best-customer-experience.png"
                    className="img-fluid"
                    alt=""
                  />
                  <h3>Quality</h3>
                  <p>
                    We guarantee that every lead is accurate, relevant, and
                    primed for conversion.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div className="col-lg-3" data-aos="fade-up" data-aos-delay={300}>
                <div className="card">
                  <img
                    src="002-customer-review.png"
                    className="img-fluid"
                    alt=""
                  />
                  <h3>Innovation</h3>
                  <p>
                    We continually enhance our lead generation strategies to
                    maintain a competitive edge.
                  </p>
                </div>
              </div>
              <div className="col-lg-3" data-aos="fade-up" data-aos-delay={300}>
                <div className="card">
                  <img src="004-handshake.png" className="img-fluid" alt="" />
                  <h3>Partnership</h3>
                  <p>
                    We collaborate closely with our clients, aligning with their
                    goals and objectives.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
            </div>
          </div>
        </section>
        {/* /Values Section */}
      </>

      {/* Alt Features Section */}
      <section id="services" className="alt-features section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Our Services</h2>
          <p>
            What we value most
            <br />
          </p>
        </div>
        <div className="container">
          <div className="row gy-5">
            <div
              className="col-xl-12 d-flex order-2 order-xl-1"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="row align-self-center gy-5">
                <div className="col-md-4 icon-box">
                  <div>
                    <h4>Lead Generation</h4>
                    <p>
                      We provide high-quality leads for various financial
                      products (credit cards, loans, investment services), share
                      trading companies (equities, options, etc.), and real
                      estate professionals (buyers, sellers, and renters).
                    </p>
                  </div>
                </div>
                {/* End Feature Item */}
                <div className="col-md-4 icon-box">
                  <div>
                    <h4>Data Solutions</h4>
                    <p>
                      Our proprietary data platform offers actionable insights
                      to help financial institutions optimize their marketing
                      efforts.
                    </p>
                  </div>
                </div>
                {/* End Feature Item */}
                <div className="col-md-4 icon-box">
                  <div>
                    <h4>Compliance Solutions</h4>
                    <p>
                      We ensure that all leads comply with regulatory
                      requirements, providing our partners with peace of mind.
                    </p>
                  </div>
                </div>
                {/* End Feature Item */}
                <div className="col-md-4 icon-box">
                  <div>
                    <h4>CRM Solutions</h4>
                    <p>
                      We deliver tailored CRM solutions for managing leads and
                      client relationships.
                    </p>
                  </div>
                </div>
                {/* End Feature Item */}
                <div className="col-md-4 icon-box">
                  <div>
                    <h4>Political Surveys</h4>
                    <p>
                      We conduct surveys on political topics, including
                      elections and policy issues.
                    </p>
                  </div>
                </div>
                {/* End Feature Item */}
                <div className="col-md-4 icon-box">
                  <div>
                    <h4>Research Reports</h4>
                    <p>
                      We publish comprehensive analyses of our survey findings.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 icon-box">
                  <div>
                    <h4>Data Analytics</h4>
                    <p>
                      We offer customized data solutions for political
                      organizations.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 icon-box">
                  <div>
                    <h4>Consulting</h4>
                    <p>
                      We provide expert advice on political strategy and
                      messaging.
                    </p>
                  </div>
                </div>
                {/* End Feature Item */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Alt Features Section */}

      {/* Features Section */}
      <section id="choose" className="features section">
        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <h2>Choose</h2>
          <p>
            Why Choose Us?
            <br />
          </p>
        </div>
        {/* End Section Title */}
        <div className="container">
          <div className="row gy-5">
            <div className="col-xl-6" data-aos="zoom-out" data-aos-delay={100}>
              <img src="features.png" className="img-fluid" alt="" />
            </div>
            <div className="col-xl-6 d-flex">
              <div className="row align-self-center gy-4">
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check" />
                    <h3>Proven success in lead generation</h3>
                  </div>
                </div>
                {/* End Feature Item */}
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check" />
                    <h3>Deep expertise in financial services marketing</h3>
                  </div>
                </div>
                {/* End Feature Item */}
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check" />
                    <h3>
                      Tailored solutions to meet your institution’s specific
                      needs
                    </h3>
                  </div>
                </div>
                {/* End Feature Item */}
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check" />
                    <h3>Real-time lead delivery and tracking</h3>
                  </div>
                </div>
                {/* End Feature Item */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="terms&conditions" className="terms&conditions section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Terms and Conditions</h2>
          <p>
            Terms and Conditions
            <br />
          </p>
        </div>
        <div className="container">
          <i>
            {" "}
            <p>
              <b>1. **Definitions** </b> <br></br>- **"Company"** refers to
              “leadcenter.me”.
              <br></br>- **"Services"** encompasses lead generation and related
              services provided by the Company. <br></br> - **"Website"**
              denotes “www.leadcenter.me”. <br></br> - **"User"** means you, the
              visitor and user of the Website. <br></br> - **"Leads"** are
              potential customers generated through the Services. <br></br> -
              **"Clients"** refers to third-party businesses that uses Leads
              from the Company.
            </p>
            <p>
              {" "}
              <b>2. **Consent to contact**</b> <br></br> By providing your phone
              number and submitting the form on our website, you consent to
              receive autodialed and/or pre-recorded calls from us at the phone
              number providedfor; <br></br> - Sharing information about products
              and services <br></br> - Offering promotions and special offers{" "}
              <br></br> - Conducting surveys and gathering feedback <br></br> -
              Discussing my account or application status <br></br> I understand
              that this consent is not a requirement for purchasing any goods or
              services from the Company. The consent will be valid till 90 days
              from the date of submission of data.
            </p>{" "}
            <p>
              <b>3. **Lead Generation and Data** </b> <br></br>- The Company
              generates Leads through various methods, including online forms
              and surveys. <br></br> - Leads may be shared with Clients, but
              User data will not be sold or rented without consent. <br></br> -
              The Company implements reasonable measures to ensure data accuracy
              and security.
            </p>
            <p>
              {" "}
              <b> 4. **Intellectual Property** </b> <br></br>- The Website and
              Services are owned and operated by the Company. <br></br> - All
              content, trademarks, and intellectual property are legally
              protected. <br></br> - Users may not reproduce, modify, or
              distribute Company content without prior permission.
            </p>
            <p>
              {" "}
              <b>5. **Disclaimers and Limitations** </b> <br></br>- The Company
              disclaims all warranties, express or implied, concerning the
              Website and Services. <br></br> - The Company is not liable for
              any damages or losses resulting from the use of the Website or
              Services. <br></br> - The Company is not responsible for
              third-party content or services linked from the Website.{" "}
            </p>
            <p>
              <b>6. **Lead Quality and Delivery**</b> <br></br> - The Company
              aims to provide high-quality Leads but does not guarantee Lead
              accuracy or conversion rates. <br></br>- Leads will be delivered
              to Clients promptly, but the Company is not liable for any delays
              or technical issues.
            </p>
            <p>
              <b>7. **Client Responsibilities** </b> <br></br>- Clients must use
              Leads in accordance with all applicable laws and regulations.{" "}
              <br></br> - Clients are prohibited from reselling or
              redistributing Leads without the Company’s consent.
            </p>
            <p>
              {" "}
              <b>8. **Payment Terms** </b>
              <br></br> - Clients are required to pay for Leads as per the
              agreed pricing and payment terms. <br></br> - The Company reserves
              the right to suspend or terminate Services for non-payment.
            </p>
            <p>
              {" "}
              <b>
                9. **Termination** <br></br>-{" "}
              </b>
              The Company may terminate or suspend User access to the Website
              and Services at any time. <br></br> - The Company may modify or
              discontinue Services at its discretion.<br></br>
            </p>{" "}
            <p>
              <b> 10. **Governing Law**</b> <br></br>- These Terms and
              Conditions are governed by the laws of Uttar Pradesh. <br></br> -
              Any disputes will be resolved through [dispute resolution
              process].<br></br>
            </p>
            <p>
              {" "}
              <b>
                11. **Changes to Terms and Conditions** <br></br>-
              </b>{" "}
              The Company may update these Terms and Conditions periodically.{" "}
              <br></br> - Users are responsible for reviewing and agreeing to
              any changes.<br></br>
            </p>
            <p>
              {" "}
              <b>12. **Use of Website and Services** </b>
              <br></br> - Users are required to provide accurate and complete
              information when registering or using the Services. <br></br>-
              Users are responsible for maintaining the confidentiality of their
              account and password.<br></br>{" "}
            </p>
            <p>
              <b> 13. **Consent to contact Revocation**</b>
              <br></br> Users can revoke their consent to be contacted at any
              time by sending an email to the Company’s email address.
            </p>
            <br></br>
          </i>
        </div>
      </section>

      {/* contact Section */}
      <section id="contact" className="Consent section">
        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <h2>Consent</h2>
          <p>
          Contact Us
            <br />
          </p>
        </div>
        <div className="container">
          <form onSubmit={async (e) => { submitForm(e) }}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name: </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email: </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Phone Number:</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Description:</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                onClick = {handleSubmitCheck}
                checked={submitCheck}
              />
              <label className="form-check-label w-100" htmlFor="exampleCheck1">
              By clicking View Plan, you authorize leadcenter.me to call/SMS on your registered mobile number and also consent to override your existing registry in DNCR.{" "}
                <a href="#terms&conditions">Terms and Conditions*</a>
              </label>
            </div>
            <button type="submit" className="btn btn-primary" disabled={!submitCheck}>
              Submit
            </button>
          </form>
        </div>
      </section>
      {/* /footer Section */}
      <footer className="footer-section">
        <div className="container text-center">
          <p className="m-0">
            &copy; Copyright Lead Center. All Rights Reserved{" "}
          </p>
        </div>
      </footer>
    </div>
    <div className="toast-container position-fixed top-0 end-0 p-3">
      <ToastNotification show_toast={showToast} set_show_toast={setShowToast} {...toastProps} />
    </div>
    </>
  );
}
export default App;
